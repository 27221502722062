import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useStaticQuery } from 'gatsby'
import { orderLinksByCategory, openSpring } from '../utils'
import SignUp from './SignUp'
import VaultLinkNav from './VaultLinkNav'
import VaultLink from './VaultLink'
import classNames from 'classnames'
import { Info } from './Icons'
import Tooltip from 'react-tooltip'
import { motion } from 'framer-motion'
import './VaultLinkSection.scss'

const VaultLinkSection = ({}) => {
  const data = useStaticQuery(graphql`
    query vaultLinkSectionQuery {
      allSanityVaultLink {
        nodes {
          label
          link
          category {
            label
            position
            parentSection {
              label
            }
          }
          subcategory {
            label
            position
          }
          sponsored
        }
      }
      allSanitySection {
        nodes {
          label
        }
      }
      allSanityCategory {
        nodes {
          label
          position
          parentSection {
            label
          }
        }
      }
      allSanitySubcategory {
        nodes {
          label
          position
          parentCategory {
            label
          }
        }
      }
    }
  `)

  const [sortedLinks, setSortedLinks] = useState()

  useEffect(() => {
    const linksByCategory = orderLinksByCategory({
      links: data.allSanityVaultLink.nodes,
    })
    setSortedLinks(linksByCategory)
  }, [])

  return (
    <motion.section
      className="VaultLinkSection"
      initial={{ y: '-10px', opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { ...openSpring, delay: 0.6 } }}
    >
      <div className="VaultLinkSection_linksSide">
        {sortedLinks &&
          sortedLinks.map((section, idx) => {
            return (
              <section
                key={section.label}
                className="VaultLinkSection_parentSection"
              >
                <div className="VaultLinkSection_parentSection_heading">
                  <h2>{section.label}</h2>
                  {idx === 0 && (
                    <>
                      <Info
                        data-tip
                        data-for="vault-info"
                        aria-describedby="vault-info"
                      />
                      <Tooltip
                        id="vault-info"
                        effect="solid"
                        place="right"
                        arrowColor="#f0f0f0"
                        className="tooltip"
                        role="tooltip"
                        data-multiline
                      >
                        <p>This section is the repo.</p>
                        <p>A list of helpful links, only the best.</p>
                      </Tooltip>
                    </>
                  )}
                </div>
                {section.categories.map((category, index) => (
                  <span key={category.label}>
                    {Math.floor(sortedLinks.length / 2) === index - 1 && (
                      <SignUp archive />
                    )}
                    <section
                      className={classNames('VaultLinkSection_category', {
                        'VaultLinkSection_category--noDivider':
                          Math.floor(sortedLinks.length / 2) === index,
                      })}
                      id={`${section.label}-${category.label}`}
                    >
                      <h3 className="VaultLinkSection_category_heading">
                        {category.label}
                      </h3>
                      <div className="VaultLinkSection_category_subcategoryWrap">
                        {category.subcategories.map(subcat => (
                          <div
                            key={`${category.label}-${subcat.label}`}
                            className="VaultLinkSection_subcategory"
                          >
                            <h4 className="VaultLinkSection_subcategory_heading">
                              {subcat.label}
                            </h4>
                            <ul className="VaultLinkSection_subcategory_list">
                              {subcat.links
                                .sort(function (a, b) {
                                  var nameA = a.label.toLowerCase(),
                                    nameB = b.label.toLowerCase()
                                  if (nameA < nameB)
                                    //sort string ascending
                                    return -1
                                  if (nameA > nameB) return 1
                                  return 0 //default return value (no sorting)
                                })
                                .map(link => {
                                  return (
                                    <VaultLink
                                      link={link}
                                      key={`${link.category.label}-${link.subcategory.label}-${link.label}`}
                                    />
                                  )
                                })}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </section>
                  </span>
                ))}
              </section>
            )
          })}
      </div>
      {sortedLinks && <VaultLinkNav sections={sortedLinks} />}
    </motion.section>
  )
}

export default VaultLinkSection

import React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import './VaultLink.scss'

const VaultLink = ({ link }) => (
  <li>
    <a
      href={link.link}
      target="_blank"
      className="VaultLink"
      onClick={() => {
        trackCustomEvent({
          category: link.sponsored ? 'Repo link - sponsored' : 'Repo link',
          action: 'Click',
          label: link.label,
        })
      }}
    >
      {link.label}
    </a>
    {link.sponsored && <span className="VaultLink_sponsored">Sponsored</span>}
  </li>
)

export default VaultLink

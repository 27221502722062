import React, { useState } from 'react'
import NewLinkSectionDay from './NewLinkSectionDay'
import {
  NextDayButton,
  PrevDayButton,
  ArchiveButton,
} from './NewLinkSectionButtons'
import './NewLinkSection.scss'

const NewLinkSection = ({ days, isArchiveVisible }) => {
  const [activeDay, setActiveDay] = useState(0)

  const onNext = () => setActiveDay(activeDay + 1)

  const onPrev = () => setActiveDay(activeDay - 1)

  const showNext = activeDay !== days.length - 1
  const showPrev = activeDay !== 0
  const showArchive = activeDay === days.length - 1

  return (
    <div className="NewLinkSection">
      <main>
        <div className="NewLinkSection_contentWrap">
          {/* Fake Day to keep the space available*/}
          <NewLinkSectionDay
            day="Foo"
            isActive={false}
            nodes={[
              { label: 'placeholder--1' },
              { label: 'placeholder--2' },
              { label: 'placeholder--3' },
              { label: 'placeholder--4' },
              { label: 'placeholder--5' },
            ]}
          />
          {days.map((day, index) => (
            <NewLinkSectionDay
              key={day.date}
              {...day}
              isActive={activeDay === index}
              index={index}
              activeDay={activeDay}
            />
          ))}
          <PrevDayButton onClick={onPrev} visible={showPrev} />
          <ArchiveButton visible={isArchiveVisible && showArchive} />
          <NextDayButton onClick={onNext} visible={showNext} />
        </div>
      </main>
    </div>
  )
}

export default NewLinkSection

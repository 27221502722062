import React from 'react'
import Layout from '../components/Layout'
import NewLinkSection from '../components/NewLinkSection'
import VaultLinkSection from '../components/VaultLinkSection'
import Seo from '../components/Seo'
import '../components/Base.scss'

const IndexPage = props => (
  <Layout>
    <Seo title="The best web design and dev links every day" />
    <NewLinkSection
      days={props.pageContext.days}
      isArchiveVisible={props.pageContext.isArchiveVisible}
    />
    <VaultLinkSection />
  </Layout>
)

export default IndexPage

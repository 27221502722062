import React, { useState } from 'react'
import { Menu, Cross } from './Icons'
import { getWindow } from '../utils'
import classNames from 'classnames'
import './VaultLinkNav.scss'

const VaultLinkNav = ({ sections }) => {
  const [isActive, setIsActive] = useState(false)

  const scrollIntoView = (e, id) => {
    e.preventDefault()

    if (getWindow().innerWidth <= 860) {
      setIsActive(false)
    }

    const elem = getWindow().document.getElementById(id)

    elem.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <>
      <div
        className={classNames('VaultLinkNav', {
          'VaultLinkNav--active': isActive,
        })}
      >
        <button
          className="VaultLinkNav_menuButton"
          onClick={() => setIsActive(!isActive)}
          aria-label="Show menu"
          aria-controls="vaultLinkNav"
        >
          <Menu />
        </button>
        <nav
          className="VaultLinkNav_nav"
          id="vaultLinkNav"
          aria-expanded={isActive}
        >
          {sections.map(section => (
            <div key={section.label}>
              <h5 className="VaultLinkNav_sectionHeading">{section.label}</h5>
              <ul>
                {section.categories.map(category => (
                  <li key={`sideNav-${category.label}`}>
                    <a
                      href={`#${section.label}-${category.label}`}
                      onClick={e =>
                        scrollIntoView(e, `${section.label}-${category.label}`)
                      }
                    >
                      {category.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <button
            className="VaultLinkNav_closeButton"
            onClick={() => setIsActive(false)}
          >
            Hide <Cross aria-hidden />
          </button>
        </nav>
      </div>
      <div
        className="VaultLinkNav_background"
        onClick={() => setIsActive(false)}
      />
    </>
  )
}

export default VaultLinkNav

import React from 'react'
import { ArrowRight, ArrowLeft } from './Icons'
import classNames from 'classnames'
import { Link } from 'gatsby'
import './NewLinkSectionButtons.scss'

export const NextDayButton = ({ onClick, visible }) => (
  <button
    onClick={onClick}
    aria-label="Next day"
    className={classNames('NextDayButton', {
      'NextDayButton--visible': visible,
    })}
    tabIndex={visible ? 0 : -1}
  >
    <ArrowRight />
  </button>
)

export const PrevDayButton = ({ onClick, visible }) => (
  <button
    onClick={onClick}
    aria-label="Previous day"
    className={classNames('PrevDayButton', {
      'PrevDayButton--visible': visible,
    })}
    tabIndex={visible ? 0 : -1}
  >
    <ArrowLeft />
  </button>
)

export const ArchiveButton = ({ visible }) => (
  <Link
    to="/new/1"
    className={classNames('ArchiveButton', {
      'ArchiveButton--visible': visible,
    })}
    tabIndex={visible ? 0 : -1}
  >
    Older links
  </Link>
)
